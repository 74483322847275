import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import config from '../config';

const usePendingFeedbacks = ({ skip } = { skip: false }) => {
  const [pendingFeedbacks, setPendingFeedbacks] = useState([]);

  useEffect(() => {
    if (skip) {
      return;
    }

    const fetchPendingFeedbacks = () => {
      axios
        .get(`${config.API_URL}/reviews/pending`)
        .then(({ data }) => setPendingFeedbacks(data))
        .catch(error => {
          console.error(error);
        });
    };

    fetchPendingFeedbacks();
  }, [skip]);

  const removeFeedbackFromPendingList = useCallback(feedbackId => {
    setPendingFeedbacks(prevPendingFeedbacks => prevPendingFeedbacks.filter(({ id }) => id !== feedbackId));
  }, []);

  return [pendingFeedbacks, removeFeedbackFromPendingList];
};

export { usePendingFeedbacks };
